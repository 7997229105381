
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  //IonButton,
} from "@ionic/vue";
import { useRoute, useRouter } from "vue-router";
import { reactive, ref, onMounted } from "vue";

import apiInterventi from "../services/interventi";
import { openToast } from "../services/toast";

export default {
  name: "FirmaCliente",
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    //IonButton,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const interventoID = route.params.id;
    const hasSigned = ref(false); //check if client has signed, otherwise don't save

    const x = ref(0);
    const y = ref(0);

    /********************************************************* */
    const canvas = ref<HTMLCanvasElement | null>(null);
    /********************************************************* */
    const point = reactive({
      x: 0,
      y: 0,
    });
    const moving = ref(false);
    const ctx = ref(null);
    //TOUCH START
    function mStart(e) {
      //console.log(e);
      hasSigned.value = true;

      const x = e.touches[0].clientX - e.target.offsetLeft;
      const y = e.touches[0].clientY - e.target.offsetTop; // get the coordinates of the touch point on the canvas
      point.x = x;
      point.y = y;
      ctx.value.beginPath();
      moving.value = true;
    }
    //SLIDE START
    function mMove(e) {
      if (moving.value) {
        const x = e.touches[0].clientX - e.target.offsetLeft;
        const y = e.touches[0].clientY - e.target.offsetTop; // get the coordinates of the touch point on the canvas
        ctx.value.moveTo(point.x, point.y); // move the path to the specified point in the canvas without creating a line (starting point)
        ctx.value.lineTo(x, y); // add a new point, and then create a line from the point to the last specified point in the canvas, without creating a line
        ctx.value.stroke(); // draw
        point.x = x;
        point.y = y; // reset the point coordinate to the previous coordinate
      }
    }
    //SLIDE END
    function mEnd() {
      if (moving.value) {
        ctx.value.closePath(); // stop drawing
        moving.value = false; // turn off the draw switch
      }
    }
    function clearCanvas() {
      ctx.value = canvas.value.getContext("2d");
      const dataURL = canvas.value.toDataURL();
      ctx.value.clearRect(0, 0, canvas.value.width, canvas.value.height);
      hasSigned.value = false;
    }
    function getCanvas() {
      const dataURL = canvas.value.toDataURL();
      //console.log(dataURL);
      return dataURL;
    }

    function salvaFirmaCliente() {
      if (hasSigned.value) {
        //Get canvas content and remove 'data:image/png;base64,' from string
        const sign = getCanvas().replace("data:image/png;base64,", "");

        apiInterventi
          .saveCustomerSign(sign, interventoID as string)
          .then((response) => {
            router.back();
            //router.go(-1);
            openToast("Firma cliente salvata correttamente", "success");
          })
          .catch((error) => {
            // handle error
            console.error(error);
            openToast("Errore durante il salvataggio della firma", "danger");
          })
          .finally(() => {
            //loading.value = false;
          });
      } else {
        openToast("Non puoi salvare una firma vuota", "danger");
      }
    }

    onMounted(() => {
      canvas.value.width = 290;
      canvas.value.height = 600;
      /********************************************************* */
      ctx.value = canvas.value.getContext("2d");
      ctx.value.lineWidth = 1;
      ctx.value.lineCap = "round";
      /*ctx.value.fillStyle = "white";*/
      // ctx.value.fillRect(0, 0, canvas.value.width, canvas.value.height);
      /********************************************************* */
      //canvasRef.value.getContext("2d");
    });

    return {
      salvaFirmaCliente,
      x,
      y,
      canvas,
      //touch
      mStart,
      mMove,
      mEnd,
      clearCanvas,
      getCanvas,
    };
  },
};
